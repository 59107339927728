import {useState} from "react";

function App() {
    let [clientLogos] = useState([
        {
            'clientName': 'Property Maintenance',
            'clientLogo': 'prop-maintenance.png'
        },
        {
            'clientName': 'Digital Labs',
            'clientLogo': 'digital-labs.png'
        },
        {
            'clientName': 'Crest',
            'clientLogo': 'crest.png'
        },
        {
            'clientName': 'Cuvoo',
            'clientLogo': 'Cuvoo.png'
        },
        {
            'clientName': 'Ansal',
            'clientLogo': 'ansal.png'
        },
        {
            'clientName': 'Flizone',
            'clientLogo': 'Flizone.png'
        },
        {
            'clientName': 'Indian Innovation',
            'clientLogo': 'IndianInnovation.png'
        },
        {
            'clientName': 'NewBee Works',
            'clientLogo': 'NewbeeLogo.png'
        },
        {
            'clientName': 'Oculus',
            'clientLogo': 'oculus_logo.png'
        },
        {
            'clientName': 'BookGuru',
            'clientLogo': 'bookguru.png'
        },
        {
            'clientName': 'KlapKlap',
            'clientLogo': 'Klapklap.png'
        },
        {
            'clientName': 'Compu Trader',
            'clientLogo': 'computrader.png'
        }
    ]),
        [customerReview, updateCustomerReview] = useState([
            {
                "short": "Our website was developed from NA Tech and they did an amazing job.I Would recommend them for any development related project.",
                "long": "Our website was developed from NA Tech and they did an amazing job.I Would recommend them for any development related project.",
                "name": "AMIT PANDEY",
                "designation": "Director",
                "organization": "Ansal Infosys",
                "image": "AmitPandey.jpeg",
                "active": false
            },
            {
                "short": "We got our mobile application developed from NA Tech and they did amazing work. They delivered the mobile app on time with all the requirements.",
                "long": "We got our mobile application developed from NA Tech and they did amazing work. They delivered the mobile app on time with all the requirements. A great set of developers who understands each and every requirement and deliver it accordingly. I would love to work with them in future for our products.",
                "name": "RAMESH SOLANKI",
                "designation": "Director",
                "organization": "Flizone Stores Pvt Ltd",
                "image": "Ramesh_Flizone.jpg",
                "active": true
            },
            {
                "short": "NA Tech was entrusted with the task of designing the logo for our Company. They came out with a highly impressive & creative design which was a ....",
                "long": "NA Tech was entrusted with the task of designing the logo for our Company. They came out with a highly impressive & creative design which was a total delight to us. We wish them grand success in their field of business.",
                "name": "SUMANT KUMAR SINHA",
                "designation": "CEO",
                "organization": "Crest Engineering & Plastic Private Ltd",
                "image": "SumantKrSinha_Crest.jpeg",
                "active": false
            }
        ]);

    const showCustomerReview = (index) => {
        let input = [...customerReview];
        for (let i=0; i<input.length; i++) {
            if (i!== index) {
                input[i]["active"] = false;
            }
            else {
                input[i]["active"] = true;
            }

        }
        updateCustomerReview(input);
    }


  return (
      <div className={"main-container"}>
          {/*Code for main header starts here*/}
          <header className={"main-header"}>
              <div className={"logo-container"}>
                  <img src={require("./images/logo.png").default} alt={"NA Tech"}/>
              </div>
              {/*Code for navbar starts here*/}
              <nav className={"navbar-container"}>
                  <a href={"/"} className={"menu-item"}>
                      Home
                  </a>
                  <a href={"#about"} className={"menu-item"}>
                      About Us
                  </a>
                  <a href={"#services"} className={"menu-item"}>
                      Services
                  </a>
                  <a href={"#customer-review"} className={"menu-item"}>
                      Customer Review
                  </a>
                  <a href={"#clients"} className={"menu-item"}>
                      Clients
                  </a>
                  {/*<a href={"#blog"} className={"menu-item"}>*/}
                  {/*    Blog*/}
                  {/*</a>*/}
                  {/*<a href={"#contact"} className={"menu-item"}>*/}
                  {/*    Contact Us*/}
                  {/*</a>*/}
              </nav>
              {/*Code for navbar ends here*/}
          </header>
          {/*Code for main header ends here*/}

          {/*Code for content body starts here*/}
          <div className={"body-main-container"}>
              <section id={"home"} className={"module-section"}>
                  <div className={"main-banner-content"}>
                      <span className={"heading"}>WELCOME TO<br/><b>NA TECH</b></span>
                      <span className={"content"}>Mobile + Web Innovators</span>
                  </div>
              </section>

              <section id={"about"} className={"module-section"}>
                  <div className={"section-body"}>
                      <div className={"blue-bg"}>
                          <div className={"section-right-layer"}>
                              <h1 className={"section-main-heading"}>WHO WE ARE</h1>
                              <p className={"section-title"}>
                                  We Build Smarter and Highly Usable Stuff!
                              </p>
                              <p className={"section-content"}>
                                  Nuevo Alture Tech Ventures is an IT-enabled Web and Mobile Application Development service providing company in the market since 2017. Nuevo Alture Tech Ventures provides two major types of service. One is Web Solution that consists of high-quality Web Design & Development, Website Maintenance, Social Networking Platform Development, E-Commerce Solutions, CMS Solutions, e-Prochar as E-mail Marketing Service, Managed Web Hosting Service and Domain Registration Service. Another one is Mobile Application Development in both Android & iOS platforms.
                              </p>
                          </div>
                          <div className={"our-beliefs"}>
                          <p className={"section-title"}>
                              WE BELIEVE
                          </p>
                          <p className={"section-title-sub-content"}>in Unleashing the Possibilities</p>
                          <p className={"section-content"}>
                              How? We help individuals / inventors / entrepreneurs / designers / developers / fresh-thinkers to achieve their dream and goal by showing them how to increase their overall productivity, identity, and belief in their ability towards success. As you read our testimonials you will find that many of our clients identify our rigorous accountability approach as well as our ability to increase their self-belief as the driving forces behind them remaining focused on pursuing and achieving their dreams and goals.
                          </p>
                      </div>
                      </div>
                      <div className={"tiles-wrapper"}>
                          <div className={"tile col-lg-6 col-sm-12"}>
                              <h2 className={"tile-heading"}>OUR VISION</h2>
                              <div className={"tile-body "}>
                                  <img src={require('./images/vision.jpg').default} alt={"Our Vision"} />
                                  <p className={"section-content"}>
                                      To be the leading company in the web & Mobile Application Development field that empowers individuals & companies to transform their ideas into reality.
                                  </p>
                              </div>
                          </div>
                          <div className={"tile col-lg-6 col-sm-12"}>
                              <h2 className={"tile-heading"}>OUR MISSION</h2>
                              <div className={"tile-body "}>
                                  <img src={require('./images/mission.jpg').default} alt={"Our Mission"} />
                                  <p className={"section-content"}>
                                      Our mission is 100% client satisfaction and we focused on versatile project management with the aim to provide Web & Mobile App solutions with global acceptability.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>

              <section id={"services"} className={"module-section"}>
                  <div className={"section-body"}>
                      <div className={"col-lg-6 col-sm-12"}>
                          <h1 className={"section-main-heading"}>OUR SERVICES</h1>
                          <p className={"section-title"}>
                              We Build Smarter and Highly Usable Stuff!
                          </p>
                          <p className={"section-content"}>
                              Through the creative use of graphic design, digital technology, and online strategy, we solve problems and develop opportunities that help place our clients ahead of their competition.
                          </p>
                      </div>
                      <div className={"col-lg-6 col-sm-12"}>
                          <img src={require('./images/our-services.png').default} alt={"Services"}/>
                      </div>
                  </div>
              </section>

              <section id={"customer-review"} className={"module-section"}>
                  <div className={"section-body"}>
                      <h1 className={"section-main-heading"}>WHAT OUR CUSTOMERS SAY...</h1>
                      <div className={"customer-review-wrapper"}>
                          <div className={"customer-review-content"}>
                              {customerReview.map( (review, index) =>
                                      <div className={"customer-review-content-box " + (review.active ? "active" : "")} key={index}
                                            onClick={() => showCustomerReview(index)}>
                                          {review.active ? review.long : review.short}
                                          <div className={"customer-info"}>
                                              <div className={"customer-name"}>{review.name}</div>
                                              <div className={"customer-designation"}>{review.designation}, {review.organization}</div>
                                          </div>
                                      </div>
                                  )}
                          </div>
                          <div className={"customer-review-pic"}>
                              {customerReview.map( (review, index) =>
                                      review.active && <img src={require('./images/ClientLogo/' + review.image).default}
                                           alt={review.name + ',' + review.organization} key={index}/>

                              )}
                          </div>
                      </div>
                  </div>
              </section>

              <section id={"clients"} className={"module-section"}>
                  <div className={"section-body"}>
                      <h1 className={"section-main-heading"}>OUR CLIENTS</h1>
                      {clientLogos.map((i, v) => {
                          return (
                              <div className={"client-logo"} key={v}>
                                  <img src={require('./images/ClientLogo/' + i.clientLogo).default} alt={i.clientName} />
                              </div>
                          )
                      })}
                  </div>
              </section>

              {/*<section id={"portfolio"} className={"module-section"}>
                  <h1 className={"section-main-heading"}>PORTFOLIO</h1>
                  <div className={"section-body"}>
                      <div className={"col-lg-4"}>

                      </div>
                      <div className={"col-lg-4"}>

                      </div>
                      <div className={"col-lg-4"}>

                      </div>
                  </div>
              </section>

              <section id={"blog"} className={"module-section"}>

              </section>*/}

              <section id={"contact"} className={"module-section"}>

              </section>
          </div>
          {/*Code for content body ends here*/}

          {/*Code for footer starts here*/}
          <footer>
              <div className={"footer-content"}>
                  <div className={"contact-info col-lg-6"}>
                      <h2 className={"sub-heading"}>Contact Us</h2>
                      <a href={"mailto:contact@nuevoalture.com "}>contact@nuevoalture.com</a>
                      <a href="tel:+91-7004710966">+91-7004710966</a>
                      <div className={"address"}>
                          Lotus Petals Apt, Off B G Road<br/>Doddakamanahalli, Bangalore 560083
                      </div>
                  </div>
                  <div className={"social-media col-lg-6"}>
                      <a href={'https://www.facebook.com/natech17/'} target={"_blank"} rel={"noreferrer"}>
                          <i className={"icon icon-facebook"}></i>
                      </a>
                      <a href={'https://www.linkedin.com/company/nuevo-alture-tech-ventures-llp/about/'} target={"_blank"} rel={"noreferrer"}>
                          <i className={"icon icon-linkedin"}></i>
                      </a>
                      <a href={"https://wa.me/+917004710966?text=Hi"} target={"_blank"} rel={"noreferrer"}>
                        <i className={"icon icon-whatsapp"}></i>
                      </a>
                  </div>
              </div>

              <div className={"clearfix"}></div>
              <div className={"copy-right"}>
                  &copy;2021 by Nuevo Alture Tech Ventures LLP
              </div>
          </footer>
          {/*Code for footer ends here*/}
      </div>
  );
}

export default App;
